// src/components/features/Cart/Checkout.js
import React, { useContext, useState, useEffect, useRef } from "react";
import CartContext from "../../../context/CartContext";
import { BusinessContext } from "../../../context/BusinessContext";
import {
  HomeOutlined,
  EnvironmentOutlined,
  ShoppingOutlined,
  CreditCardOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import {
  Button,
  Breadcrumb,
  Row,
  Col,
  Typography,
  Form,
  Input,
  Modal,
  Radio,
  Flex,
  Space,
  Divider,
  List,
  Spin,
  Empty,
  Skeleton,
  message,
  InputNumber
} from "antd";


import { useNavigate } from "react-router-dom";
import "../../features/Cart/Checkout.css";
import "../../features/Cart/Cart.css";
import visacard from "../../../assets/images/visa.svg";
import americancard from "../../../assets/images/american-express.svg";
import mastercard from "../../../assets/images/mastercard.svg";
import payoneercard from "../../../assets/images/payoneer.svg";
import applepaycard from "../../../assets/images/applepay.svg";
import jcbcard from "../../../assets/images/jcb.svg";
import emptycart from "../../../assets/images/empty_cart.png";
import discovercard from "../../../assets/images/discover.svg";
import banktransfercard from "../../../assets/images/bank-transfer.svg";
import { initMap } from '../../../utils/initMap';
import { loadGoogleMapsScript } from "../../../utils/loadGoogleMapsScript";
import { loadStripe } from '@stripe/stripe-js';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { StripePaymentSubmit, fetchUserAddress, fetchGuestUserAddress, AddGuestUserAddress, AddUserAddress, CheckDeliveryUserAddress, CheckDeliveryGuestAddress, OrderPaymentSubmit } from '../../../utils/api';

const { TextArea } = Input;
const { Title, Link } = Typography;

const cardStyle = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};

const Checkout = () => {

  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("credit");
  const navigate = useNavigate();
  const [Addressform] = Form.useForm();
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [userId, setUserId] = useState(null);
  const [addressID, setaddressID] = useState(null);
  const [userLoggedAddresses, setUserLoggedAddresses] = useState([]);
  const [userGuestAddresses, setUserGuestAddresses] = useState([]);
  const [orderby, setorderby] = useState(0);
  const [selectedDeliveryAddress, setSelectedDeliveryAddress] = useState(null);
  const [deliveryCharge, setDeliveryCharge] = useState(0);
  const [isLoggedAddressModalVisible, setIsLoggedAddressModalVisible] = useState(false);
  const [isGuestLoggedAddressModalVisible, setIsGuestLoggedAddressModalVisible] = useState(false);
  const updateMapData = (address, lat, lng, zipCode, houseNumberAndStreet, locality, country) => {
    Addressform.setFieldsValue({
      full_address: address ?? '',
      latitude: lat ?? '',
      longitude: lng ?? '',
      zipcode: zipCode ?? '',
      house_number_street: houseNumberAndStreet ?? '',
      locality: locality ?? '',
      country: country ?? '',
    });
  };

  
  // Show modal
  const showLoggedAddressModal = () => {
    setIsLoggedAddressModalVisible(true);
  };
  // Hide modal
  const handleLoggedAddressCancel = () => {
    setIsLoggedAddressModalVisible(false);
  };

  const showGuestLoggedAddressModal = () => {
    setIsGuestLoggedAddressModalVisible(true);
  };
  // Hide modal
  const handleGuestLoggedAddressCancel = () => {
    setIsGuestLoggedAddressModalVisible(false);
  };
  
  const [driverTips, setDriverTips] = useState(0);
  const [specialComment, setSpecialComment] = useState("");
  const [CouponCode, setCouponCode] = useState("");
  const { businessInfo, loading } = useContext(BusinessContext);
  const { cart, updateItem, updateOrderType, cookiesId, ApplyCouponCode } = useContext(CartContext);
  const orderType = cart?.Carts.order_type;
  const comparisionDiscount = cart?.Carts?.ComparisonRate ?? 0;
  const dis_type = cart?.Carts?.dis_type;
  const dis_amount = cart?.Carts?.dis_amount;
  let coupon_discount = 0;
  const [Cartloading, setCartLoading] = useState(false);

  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
  };
  const handleDriverTipChange = (value) => {
    setDriverTips(value); // Update state with the new value
  };
  const handleSpecialCommentChange = (e) => {
    setSpecialComment(e.target.value);
  };

  const handleCouponCodeChange = (e) => {
    setCouponCode(e.target.value); // Update state
  };

  const handleUpdateItem = async (itemId, newQuantity) => {
    setCartLoading(true); // Start loading
    try {
      await updateItem(itemId, newQuantity); // Perform the update operation
    } catch (error) {
      console.error("Failed to update item:", error);
    } finally {
      setCartLoading(false); // Stop loading
    }
  };
  const handleUpdateOrderType = async (order_type) => {
    setCartLoading(true); // Start loading
    try {
      await updateOrderType(order_type); // Perform the update operation
    } catch (error) {
      console.error("Failed to update item:", error);
    } finally {
      setCartLoading(false); // Stop loading
    }
  };
  const CustomEmpty = () => (
    <Empty
      image={emptycart} // Replace with your custom icon URL
      imageStyle={{
        height: 80,
      }}
      description={
        <div>
          <h2>Your Cart is Empty!</h2>
          <p>Must add items to the cart before you proceed to checkout.</p>
        </div>
      }
    />
  );

  const handleDeliveryAddressChange = async (e) => {
    try {
      const formData = {
        cookie_id: cookiesId,
        address_id: e.target.value 
      };
      setaddressID(e.target.value);
      let response; 
      if (localStorage.getItem('userId')) {
      response = await CheckDeliveryUserAddress(formData);
      }
      else
      {
        response = await CheckDeliveryGuestAddress(formData);
      }
      if (response && response.status === "success") {
        setSelectedDeliveryAddress(e.target.value); 
        setDeliveryCharge(parseFloat(response.data));  
        message.success({
          content: response.message || "Delivery charge fetched successfully.",
          duration: 5, 
        });
      } else {
        setSelectedDeliveryAddress("");  
        setDeliveryCharge("");  
        message.error({
          content: response.message || "Failed to fetch delivery charge. Please try again.",
          duration: 5, 
        });
      }
    } catch (error) {
      setSelectedDeliveryAddress("");  
      setDeliveryCharge("");  
      console.error("Error:", error);  
      message.error({
        content: "An unexpected error occurred. Please try again later.",
        duration: 5, 
      });
    }
  };
  
  const subtotal = cart.data?.reduce(
    (total, item) => total + item.quantity * item.delivery_item_price,
    0
  ) || 0;

  if (businessInfo?.businessInfo?.[0]?.portal_info?.[0]?.delivery_type_enable === 0) {
    const freeDeliveryThreshold = businessInfo?.businessInfo?.[0]?.charge_info?.[0]?.free_delivery_above_subTotal;
    const defaultDeliveryCharge = businessInfo?.businessInfo?.[0]?.charge_info?.[0]?.default_delivery_charge ?? 0;

    if (freeDeliveryThreshold && subtotal < freeDeliveryThreshold) {
      setDeliveryCharge(Number(defaultDeliveryCharge));
    }
  }
  let PackageFee = 0;
  if (businessInfo?.businessInfo?.[0]?.charge_info?.[0]?.packaging_charge_enable === 1) {
    PackageFee = Number(businessInfo?.businessInfo?.[0]?.charge_info?.[0]?.packaging_charge ?? 0);
  }
  const discountRate = businessInfo?.businessInfo?.[0]?.pdq2_agreed_discount / 100 || 0;
  const discount = subtotal * discountRate; // Calculate the discount
  const TotalComparisonDiscount = subtotal * comparisionDiscount;

  if (dis_type && dis_amount) {
    if (dis_type === "1") {
      coupon_discount = subtotal * (dis_amount / 100); // Percentage discount
    } else if (dis_type === "0") {
      coupon_discount = parseFloat(dis_amount); // Fixed amount discount
    }
  } else {
  }

  let grandTotal;
  if (orderType === 0) {
    grandTotal = subtotal + deliveryCharge + PackageFee + driverTips - (discount + coupon_discount);
  } else {
    grandTotal = subtotal - (discount + coupon_discount);
  }
  const totalSavings = (discount + TotalComparisonDiscount + coupon_discount);

  const fetchLoggedAddress = async (userId) => {
    if (!userId) {
      console.error("User ID is required to fetch addresses.");
      return; // Exit early if no user ID
    }
    const loggedAddresses = await fetchUserAddress(userId);
    if (loggedAddresses) {
      setUserLoggedAddresses(loggedAddresses.data); // Update the state with the fetched addresses
    }
  };

  const GuestUserAddress = async (cookiesId) => {
    if (!cookiesId) {
      console.error("cookiesId is required to fetch addresses.");
      return; // Exit early if no cookiesId
    }
    try {
      const guestAddresses = await fetchGuestUserAddress(cookiesId);
      if (guestAddresses && guestAddresses.data) {
        setUserGuestAddresses(guestAddresses.data); // Update the state with the fetched addresses
       // console.log("User Guest Address Len", guestAddresses.data.length);
        if (guestAddresses.data.length === 0) {
          //console.log("User Guest Address Len", guestAddresses.data.length);
        //  console.log("Do not load initMap");
          loadGoogleMapsScript(() => initMap(updateMapData));
        }
      } else {
        console.error("No addresses found or invalid data format.");
      }
    } catch (error) {
      console.error("Error fetching guest user addresses:", error);
    }
  };
  

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    const userIdFromStorage = localStorage.getItem('userId') || '';
    setUserId(userIdFromStorage);
    if (token && userIdFromStorage) {
      setUserLoggedIn(true);
      setorderby(1);
      setUserId(userIdFromStorage);
      fetchLoggedAddress(userIdFromStorage); // Fetch addresses when user is logged in
      if (isLoggedAddressModalVisible) {
        setTimeout(() => {
          loadGoogleMapsScript(() => initMap(updateMapData));
        }, 300);
      }
    } else {
      setorderby(0);
      setUserLoggedIn(false);
      GuestUserAddress(cookiesId);
      if (isGuestLoggedAddressModalVisible) {
        setTimeout(() => {
          loadGoogleMapsScript(() => initMap(updateMapData));
        }, 300);
      }
    }
  }, [isLoggedAddressModalVisible, cookiesId, isGuestLoggedAddressModalVisible]);

  const handleUserAddressSubmit = async (values) => {
    try {
      const formData = {
        cookie_id: cookiesId,
        user_id: userId,
        full_address: values.full_address,
        mobile_number: values.mobile_number,
        latitude: values.latitude,
        longitude: values.longitude,
        locality: values.locality,
        house_number_street: values.house_number_street,
        country: values.country,
        zipcode: values.zipcode,
        address_label: values.address_label,
      };
      const response = await AddUserAddress(formData);
    
      if (response && response.status === "success" && response.status_code === 200) {
        setIsLoggedAddressModalVisible(false);
        fetchLoggedAddress(userId);
        message.success({
          content: response.message || "Your address has been added successfully.",
          duration: 5, // Display for 5 seconds
        });
      } else {
        message.error({
          content: response.message || "Failed to add the address. Please try again.",
          duration: 5,
        });
      }
    } catch (error) {
      message.error({
        content: "An unexpected error occurred. Please try again later.",
        duration: 5,
      });
    }
  };

  const handleGuestAddressSubmit = async (values) => {
    try {
      const formData = {
        cookie_id: cookiesId,
        full_address: values.full_address,
        full_name: values.full_name,
        mobile_number: values.mobile_number,
        email: values.email,
        latitude: values.latitude,
        longitude: values.longitude,
        locality: values.locality,
        house_number_street: values.house_number_street,
        country: values.country,
        zipcode: values.zipcode,
      };
      const response = await AddGuestUserAddress(formData);
      if (response && response.success === "success" && response.status_code === 200) {
        if (isGuestLoggedAddressModalVisible) {
          setIsGuestLoggedAddressModalVisible(false);
        }
        GuestUserAddress(cookiesId);
        message.success({
          content: "Your address has been added successfully.",
          duration: 5, // Display for 5 seconds
        });
      } else {
        message.error({
          content: response.message || "Failed to add the address. Please try again.",
          duration: 5,
        });
      }
    } catch (error) {
      message.error({
        content: "An unexpected error occurred. Please try again later.",
        duration: 5,
      });
    }
  };

  const handleCouponCode = async () => {
    setCartLoading(true); // Start loading
    try {
      const formData = {
        cookie_id: cookiesId,
        user_id: userId,
        subtotal: subtotal,
        orderType: orderType,
        couponCode: CouponCode
      };
      const response = await ApplyCouponCode(formData);
      if (response && response.success) {
        message.success({
          content: "The coupon code has been successfully applied.",
          duration: 5, // Display for 5 seconds
        });
        setCartLoading(false); // Start loading
      } else {
        message.error({
          content: response.message || "Invalid Coupon Code.",
          duration: 5,
        });
        setCartLoading(false); // Start loading
      }
    } catch (error) {
      message.error({
        content: "Invalid Coupon Code.",
        duration: 5,
      });
      setCartLoading(false); // Start loading

    }
  };

  const handlePlaceOrder = async (e) => {
    e.preventDefault();
  
    if (!stripe || !elements) {
      message.error("Stripe.js has not loaded. Please try again later.");
      return;
    }
    setProcessing(true);
    setError(null);
  
    try {
      if (paymentMethod === "credit") {
        const cardElement = elements.getElement(CardElement);
  
        // Create Stripe token
        const { token, error } = await stripe.createToken(cardElement);
  
        if (error) {
          setError(error.message);
          message.error(error.message);
          setProcessing(false);
          return;
        }
  
        // Prepare data for Stripe payment
        const stripeData = {
          amount: grandTotal,
          token: token.id, // Ensure token is passed correctly
        };
  
        // Process Stripe payment
        const stripeResponse = await StripePaymentSubmit(stripeData);
  
        if (
          stripeResponse &&
          stripeResponse.status === "success" &&
          stripeResponse.status_code === 200
        ) {
          // Prepare order data
          const orderData = {
            cookie_id: cookiesId ?? "",
            orderby,
            user_id: userId ?? "",
            subtotal: subtotal ?? "0",
            orderType: orderType ?? "",
            discountRate: discountRate ?? "0",
            discount: discount ?? "0",
            comparisionDiscount: comparisionDiscount ?? "0",
            TotalComparisonDiscount: TotalComparisonDiscount ?? "0",
            coupon_discount: coupon_discount ?? "0",
            deliveryCharge: deliveryCharge ?? "0",
            PackageFee: PackageFee ?? "0",
            driverTips: driverTips ?? "0",
            grandTotal: grandTotal ?? "0",
            totalSavings: totalSavings ?? "0",
            paymentMethod: paymentMethod ?? "",
            address_id: addressID ?? "",
            payment_status: "1",
            instructions: specialComment ?? "",
            offer_discount: "0",
            voucher_discount: "0",
            stripe_payment_status: stripeResponse.stripe_payment_status,
            stripe_transaction_id: stripeResponse.stripe_transaction_id,
            stripe_created_at: stripeResponse.stripe_created_at,
          };
  
          // Submit order
          const orderResponse = await OrderPaymentSubmit(orderData);
  
          if (
            orderResponse &&
            orderResponse.status === "success" &&
            orderResponse.status_code === 200
          ) {
            const orderId = orderResponse.orderId;
            message.success("Payment successful. Your order has been placed.");
            navigate(`/thank-you/${orderId}`);
            window.location.reload(); // Clear form and reload page
          } else {
            throw new Error(
              orderResponse?.message ||
                "An error occurred while placing your order."
            );
          }
        } else {
          throw new Error(
            stripeResponse?.message || "Failed to process payment with Stripe."
          );
        }
      } else {
        // Handle cash payment if applicable
        await handleCashPayment();
      }
    } catch (err) {
      message.error(err.message || "An unexpected error occurred.");
      setError(err.message);
    } finally {
      setProcessing(false);
    }
  };
  
  
  const handleCashPayment = async () => {
    setCartLoading(true);
    setProcessing(true);
    try {
      // Prepare the order data
      const OrderformData = {
        cookie_id: cookiesId ?? '', 
        orderby: orderby, 
        user_id: userId ?? '', 
        subtotal: subtotal ?? '0', 
        orderType: orderType ?? '', 
        discountRate: discountRate ?? '0', 
        discount: discount ?? '0', 
        comparisionDiscount: comparisionDiscount ?? '0', 
        TotalComparisonDiscount: TotalComparisonDiscount ?? '0', 
        coupon_discount: coupon_discount ?? '0', 
        deliveryCharge: deliveryCharge ?? '0', 
        PackageFee: PackageFee ?? '0', 
        driverTips: driverTips ?? '0', 
        grandTotal: grandTotal ?? '0', 
        totalSavings: totalSavings ?? '0', 
        paymentMethod: paymentMethod ?? '', 
        address_id: addressID ?? '', 
        payment_status:'0',
        instructions: specialComment ?? '',
        offer_discount: '0',
        voucher_discount: '0',
      };
      // Call the API for order submission
      const response = await OrderPaymentSubmit(OrderformData);
      // Check if the response is successful
      if (response && response.status === "success" && response.status_code === 200) {
        const orderId = response.orderId; 
        navigate(`/thank-you/${orderId}`);
        window.location.reload();  
        message.success({
          content: "Payment successful. Your order has been placed.",
          duration: 5,
        });
      } else {
        message.error({
          content: response.message || "An error occurred while processing the payment.",
          duration: 5,
        });
      }
  
    } catch (error) {
      message.error({
        content: error.message || "There was a problem with the payment. Please try again.",
        duration: 5,
      });
    } finally {
      setCartLoading(false);
      setProcessing(false);
    }
  };

  return (
    <div className="breadCrumbSection">
      <div className="custom_container">
        <Breadcrumb
          className="breadCrumbNav"
          items={[
            {
              href: "",
              title: (
                <>
                  <HomeOutlined />
                  <span>Home</span>
                </>
              ),
            },
            {
              title: "Checkout",
            },
          ]}
        />
        <div className="orderCheckoutSection">
          {cart.data.length > 0 ? (
            <Row gutter={30}>
              <Col xs={24} sm={15} md={15} xl={15} xxl={15}>
                {!userLoggedIn && (
                  userGuestAddresses.length > 0 ? (
                    <>
                    <div className="orderWelcomeSection commonSection">
                      <div className="checkoutSectionMainWrap">
                        <div className="checkoutLeftIcon">
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 28 28"
                            fill="#07D607"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="14" cy="14" r="14"></circle>
                            <path
                              d="M14.25 7C11.3555 7 9 9.37183 9 12.2879C9 16.4313 13.7565 20.7095 13.9589 20.8892C14.0423 20.9632 14.1462 21 14.25 21C14.3538 21 14.4577 20.9633 14.5411 20.8898C14.7435 20.7095 19.5 16.4313 19.5 12.2879C19.5 9.37183 17.1445 7 14.25 7ZM14.25 15.1667C12.6417 15.1667 11.3333 13.8582 11.3333 12.25C11.3333 10.6417 12.6417 9.33333 14.25 9.33333C15.8582 9.33333 17.1667 10.6417 17.1667 12.25C17.1667 13.8582 15.8582 15.1667 14.25 15.1667Z"
                              fill="white"
                            ></path>
                          </svg>
                        </div>
                        <div className="checkoutRightWrap">
                          <Title level={3} className="customHeading">
                            Choose Delivery Address
                          </Title>
                          <div className="addAddressWrap" onClick={showGuestLoggedAddressModal}>
                            <svg width="24px" height="24px" viewBox="0 0 24 24" fill="#009de0">
                              <path d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0zm5.25 13H13v4.25c0 .552-.448 1-1 1s-1-.448-1-1V13H6.75c-.552 0-1-.448-1-1s.448-1 1-1H11V6.75c0-.552.448-1 1-1s1 .448 1 1V11h4.25c.552 0 1 .448 1 1s-.448 1-1 1z"></path>
                            </svg>
                            Add New Address
                          </div>
                          {/* Guest User's Address List */}
                          {userGuestAddresses.length > 0 ? (
                            <div className="checklistAddressList">
                              <Radio.Group
                               onChange={handleDeliveryAddressChange}
                               value={selectedDeliveryAddress}
                                className="addressRadioList"
                              >
                                <Row gutter={16}>
                                {userGuestAddresses.map((address, index) => (
                                    <Col span={12}>
                                 <Radio key={address.id} value={address.id}  style={{marginBottom:"10px"}}>
                                    <div className="addressWrap">
                                      <div className="addressDetails">
                                        <h5>
                                          <EnvironmentOutlined />
                                          {address.full_name} {/* e.g. Home, Office */}
                                        </h5>
                                        <p>{address.full_address}</p> {/* Show address details */}
                                        <p>{address.mobile_number}</p> {/* Show address details */}
                                      </div>
                                    </div>
                                  </Radio>
                                  </Col>
                                ))}
                                </Row>
                              </Radio.Group>
                            </div>
                          ) : ("")}
                        </div>
                      </div>
                    </div>
                    <Modal
                    title="Add New Address"
                    visible={isGuestLoggedAddressModalVisible}
                    onCancel={handleGuestLoggedAddressCancel}
                    maskClosable={false}
                    footer={null} // Hide default footer
                  >
                    {/* Map Viewer Start Here  */}
                    <div style={{ position: "relative", height: "350px", width: "100%", marginBottom: "10px" }}>
                      <input id="pac-input" class="controls" type="text" placeholder="Search for places" />
                      <div id="map" style={{ height: "350px", width: "100%" }}></div>
                    </div>
                    {/*  Map Viewer End Here */}
                    <Form layout="vertical" className="checkoutForm" form={Addressform} onFinish={handleGuestAddressSubmit}>
                      <ul id="addresses" style={{ display: "none" }}></ul>
                      <Form.Item name="latitude" hidden>
                        <Input />
                      </Form.Item>
                      <Form.Item name="longitude" hidden>
                        <Input />
                      </Form.Item>
                      <Form.Item name="locality" hidden>
                        <Input />
                      </Form.Item>
                      <Form.Item name="country" hidden>
                        <Input />
                      </Form.Item>

                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item
                            name="full_address"
                            label="Full Address"
                            rules={[{ required: true, message: "Please enter your Full Address!" }]}
                          >
                            <Input placeholder="Full Address" id="google_full_address" />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            label="House Number and Street"
                            name="house_number_street"
                            rules={[{ required: true, message: "Please enter your House Number and Street!" }]}
                          >
                            <Input placeholder="House Number and Street" id="house-number-street" />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            label="Post Code"
                            name="zipcode"
                            rules={[{ required: true, message: "Please enter your Post Code!" }]}
                          >
                            <Input placeholder="Post Code" id="zipcode" />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Name"
                            name="full_name"
                            rules={[{ required: true, message: "Please enter your name!" }]}
                          >
                            <Input placeholder="Name" />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item
                            label="Email Address"
                            name="email"
                            rules={[
                              { required: true, message: "Email is required!" },
                              {
                                type: "email",
                                message: "Please enter a valid email address!"
                              },
                              {
                                pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                message: "Email must be in the format: user@example.com",
                              },
                            ]}
                          >
                            <Input placeholder="Enter your email address" />
                          </Form.Item>

                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Mobile Number"
                            name="mobile_number"
                            rules={[
                              { required: true, message: "Please enter your mobile number!" },
                              {
                                pattern: /^[0-9]{11}$/, // Only allows 10 digits
                                message: "Mobile number must be exactly 11 digits!",
                              },
                            ]}
                          >
                            <Input placeholder="Mobile Number" maxLength={11} />
                          </Form.Item>

                        </Col>
                      </Row>

                      {/* Submit Button */}
                      <Form.Item>
                        <Button type="primary" className="checkoutButton" htmlType="submit">
                          Submit Address
                        </Button>
                      </Form.Item>
                    </Form>
                  </Modal>
                  </>
                  ) : ("")
                )}


                {!userLoggedIn && userGuestAddresses.length === 0 && (<div className="orderWelcomeSection commonSection">
                  <div className="checkoutSectionMainWrap">
                    <div className="checkoutLeftIcon">
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 28 28"
                        fill="#07D607"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="14" cy="14" r="14"></circle>
                        <path
                          d="M14.25 7C11.3555 7 9 9.37183 9 12.2879C9 16.4313 13.7565 20.7095 13.9589 20.8892C14.0423 20.9632 14.1462 21 14.25 21C14.3538 21 14.4577 20.9633 14.5411 20.8898C14.7435 20.7095 19.5 16.4313 19.5 12.2879C19.5 9.37183 17.1445 7 14.25 7ZM14.25 15.1667C12.6417 15.1667 11.3333 13.8582 11.3333 12.25C11.3333 10.6417 12.6417 9.33333 14.25 9.33333C15.8582 9.33333 17.1667 10.6417 17.1667 12.25C17.1667 13.8582 15.8582 15.1667 14.25 15.1667Z"
                          fill="white"
                        ></path>
                      </svg>
                    </div>
                    <div className="checkoutRightWrap">
                      <Title level={3} className="customHeading" style={{ marginBottom: "20px" }}>
                        Billing Information
                      </Title>
                      <div style={{ position: "relative", height: "350px", width: "100%", marginBottom: "10px" }}>
                        <input id="pac-input" className="controls" type="text" placeholder="Search for places" />
                        <div id="map" style={{ height: "350px", width: "100%" }}></div>
                      </div>
                      <Form layout="vertical" className="checkoutForm" form={Addressform} onFinish={handleGuestAddressSubmit}>
                        <ul id="addresses" style={{ display: "none" }}></ul>
                        <Form.Item name="latitude" hidden>
                          <Input />
                        </Form.Item>
                        <Form.Item name="longitude" hidden>
                          <Input />
                        </Form.Item>
                        <Form.Item name="locality" hidden>
                          <Input />
                        </Form.Item>
                        <Form.Item name="country" hidden>
                          <Input />
                        </Form.Item>

                        <Row gutter={16}>
                          <Col span={12}>
                            <Form.Item
                              name="full_address"
                              label="Full Address"
                              rules={[{ required: true, message: "Please enter your Full Address!" }]}
                            >
                              <Input placeholder="Full Address" id="google_full_address" />
                            </Form.Item>
                          </Col>

                          <Col span={12}>
                            <Form.Item
                              label="House Number and Street"
                              name="house_number_street"
                              rules={[{ required: true, message: "Please enter your House Number and Street!" }]}
                            >
                              <Input placeholder="House Number and Street" id="house-number-street" />
                            </Form.Item>
                          </Col>

                          <Col span={12}>
                            <Form.Item
                              label="Post Code"
                              name="zipcode"
                              rules={[{ required: true, message: "Please enter your Post Code!" }]}
                            >
                              <Input placeholder="Post Code" id="zipcode" />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              label="Name"
                              name="full_name"
                              rules={[{ required: true, message: "Please enter your name!" }]}
                            >
                              <Input placeholder="Name" />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row gutter={16}>
                          <Col span={12}>
                            <Form.Item
                              label="Email Address"
                              name="email"
                              rules={[
                                { required: true, message: "Email is required!" },
                                {
                                  type: "email",
                                  message: "Please enter a valid email address!"
                                },
                                {
                                  pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                  message: "Email must be in the format: user@example.com",
                                },
                              ]}
                            >
                              <Input placeholder="Enter your email address" />
                            </Form.Item>

                          </Col>
                          <Col span={12}>
                            <Form.Item
                              label="Mobile Number"
                              name="mobile_number"
                              rules={[
                                { required: true, message: "Please enter your mobile number!" },
                                {
                                  pattern: /^[0-9]{11}$/, // Only allows 10 digits
                                  message: "Mobile number must be exactly 11 digits!",
                                },
                              ]}
                            >
                              <Input placeholder="Mobile Number" maxLength={11} />
                            </Form.Item>

                          </Col>
                        </Row>

                        {/* Submit Button */}
                        <Form.Item>
                          <Button type="primary" className="checkoutButton" htmlType="submit">
                            Submit Address
                          </Button>
                        </Form.Item>
                      </Form>
                    </div>
                  </div>
                </div>)}



                {/* Already Logged User */}

                {userLoggedIn && (
                  <>
                    <div className="deliveryAddressSection commonSection">
                      <div className="checkoutSectionMainWrap">
                        <div className="checkoutLeftIcon">
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 28 28"
                            fill="#07D607"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="14" cy="14" r="14"></circle>
                            <path
                              d="M14.25 7C11.3555 7 9 9.37183 9 12.2879C9 16.4313 13.7565 20.7095 13.9589 20.8892C14.0423 20.9632 14.1462 21 14.25 21C14.3538 21 14.4577 20.9633 14.5411 20.8898C14.7435 20.7095 19.5 16.4313 19.5 12.2879C19.5 9.37183 17.1445 7 14.25 7ZM14.25 15.1667C12.6417 15.1667 11.3333 13.8582 11.3333 12.25C11.3333 10.6417 12.6417 9.33333 14.25 9.33333C15.8582 9.33333 17.1667 10.6417 17.1667 12.25C17.1667 13.8582 15.8582 15.1667 14.25 15.1667Z"
                              fill="white"
                            ></path>
                          </svg>
                        </div>
                        <div className="checkoutRightWrap">
                          <Title level={3} className="customHeading">
                            Choose Delivery Address
                          </Title>
                          <div className="addAddressWrap" onClick={showLoggedAddressModal}>
                            <svg width="24px" height="24px" viewBox="0 0 24 24" fill="#009de0">
                              <path d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0zm5.25 13H13v4.25c0 .552-.448 1-1 1s-1-.448-1-1V13H6.75c-.552 0-1-.448-1-1s.448-1 1-1H11V6.75c0-.552.448-1 1-1s1 .448 1 1V11h4.25c.552 0 1 .448 1 1s-.448 1-1 1z"></path>
                            </svg>
                            Add New Address
                          </div>
                          {/* User's Address List */}
                          {userLoggedAddresses.length > 0 ? (
                            <div className="checklistAddressList">
                              <Radio.Group
                                onChange={handleDeliveryAddressChange}
                                value={selectedDeliveryAddress}
                                className="addressRadioList"
                              ><Row gutter={16}>
                                {userLoggedAddresses.map((address, index) => (
                                  
                          <Col span={12}>
                                  <Radio key={address.id} value={address.id}>
                                    <div className="addressWrap">
                                      <div className="addressDetails">
                                        <h5>
                                          <HomeOutlined />
                                          {address.address_label} {/* e.g. Home, Office */}
                                        </h5>
                                        <p>{address.full_address}</p> {/* Show address details */}
                                      </div>
                                    </div>
                                  </Radio>
                                  </Col>
                                ))}
                                 </Row>
                              </Radio.Group>
                            </div>
                          ) : ("")}
                        </div>
                      </div>
                    </div>
                    <Modal
                      title="Add New Address"
                      visible={isLoggedAddressModalVisible}
                      onCancel={handleLoggedAddressCancel}
                      maskClosable={false}
                      footer={null} // Hide default footer
                    >
                      {/* Map Viewer Start Here  */}
                      <div style={{ position: "relative", height: "350px", width: "100%", marginBottom: "10px" }}>
                        <input id="pac-input" class="controls" type="text" placeholder="Search for places" />
                        <div id="map" style={{ height: "350px", width: "100%" }}></div>
                      </div>
                      {/*  Map Viewer End Here */}
                      <Form layout="vertical" className="checkoutForm" form={Addressform} onFinish={handleUserAddressSubmit}>
                        <ul id="addresses" style={{ display: "none" }}></ul>
                        <Form.Item name="latitude" hidden>
                          <Input />
                        </Form.Item>
                        <Form.Item name="longitude" hidden>
                          <Input />
                        </Form.Item>
                        <Form.Item name="locality" hidden>
                          <Input />
                        </Form.Item>
                        <Form.Item name="country" hidden>
                          <Input />
                        </Form.Item>
                        <Row gutter={16}>
                          <Col span={12}>
                            <Form.Item
                              name="full_address"
                              label="Full Address"
                              rules={[{ required: true, message: "Please enter your Full Address!" }]}
                            >
                              <Input placeholder="Full Address" id="google_full_address" />
                            </Form.Item>
                          </Col>

                          <Col span={12}>
                            <Form.Item
                              label="House Number and Street"
                              name="house_number_street"
                              rules={[{ required: true, message: "Please enter your House Number and Street!" }]}
                            >
                              <Input placeholder="House Number and Street" id="house-number-street" />
                            </Form.Item>
                          </Col>

                          <Col span={12}>
                            <Form.Item
                              label="Post Code"
                              name="zipcode"
                              rules={[{ required: true, message: "Please enter your Post Code!" }]}
                            >
                              <Input placeholder="Post Code" id="zipcode" />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              label="Mobile Number"
                              name="mobile_number"
                              rules={[
                                { required: true, message: "Please enter your mobile number!" },
                                {
                                  pattern: /^[0-9]{11}$/, // Only allows 10 digits
                                  message: "Mobile number must be exactly 11 digits!",
                                },
                              ]}
                            >
                              <Input placeholder="Mobile Number" maxLength={11} />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              label="Address Label"
                              name="address_label"
                              rules={[
                                { required: true, message: "Please enter your Address Label!" }
                              ]}
                            >
                              <Input placeholder="Address Label" />
                            </Form.Item>
                          </Col>

                          
                        </Row>
                        {/* Submit Button */}
                        <Form.Item>
                          <Button type="primary" className="checkoutButton" htmlType="submit">
                            Submit Address
                          </Button>
                        </Form.Item>
                      </Form>
                    </Modal>
                  </>
                )}


                {/* Payment Section Start Here */}
                {selectedDeliveryAddress && (
                  <div className="paymentSection commonSection">
                    <div className="checkoutSectionMainWrap">
                      <div className="checkoutLeftIcon">
                        <svg
                          width="32"
                          height="32"
                          viewBox="0 0 28 28"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="14" cy="14" r="14" fill="#000"></circle>
                          <path
                            d="M20.0453 17.4546H17.818C16.4145 17.4546 15.2726 16.3127 15.2726 14.9092C15.2726 13.5056 16.4145 12.3638 17.818 12.3638H20.0453C20.2211 12.3638 20.3634 12.2214 20.3634 12.0456V11.091C20.3634 10.4238 19.8459 9.88121 19.192 9.82859L17.3647 6.63688C17.1954 6.34169 16.9219 6.13071 16.5947 6.0431C16.2691 5.95609 15.9286 6.00179 15.6371 6.17143L9.3729 9.81833H8.27272C7.57081 9.81833 7 10.3891 7 11.091V18.7273C7 19.4292 7.57078 20 8.27272 20H19.0907C19.7926 20 20.3634 19.4292 20.3634 18.7273V17.7728C20.3634 17.5969 20.2211 17.4546 20.0453 17.4546ZM17.7593 8.60739L18.4526 9.81833H15.6794L17.7593 8.60739ZM10.6377 9.81833L15.9574 6.72138C16.1013 6.63718 16.2694 6.6148 16.43 6.6577C16.5925 6.70119 16.728 6.80622 16.8122 6.95319L16.8129 6.95435L11.8938 9.81833H10.6377Z"
                            fill="white"
                          ></path>
                          <path
                            d="M20.0465 13H17.8192C16.7665 13 15.9102 13.8563 15.9102 14.9091C15.9102 15.9618 16.7665 16.8181 17.8192 16.8181H20.0465C20.5728 16.8181 21.001 16.39 21.001 15.8636V13.9545C21.001 13.4282 20.5728 13 20.0465 13ZM17.8192 15.5454C17.4684 15.5454 17.1829 15.2599 17.1829 14.9091C17.1829 14.5583 17.4684 14.2727 17.8192 14.2727C18.17 14.2727 18.4556 14.5583 18.4556 14.9091C18.4556 15.2599 18.17 15.5454 17.8192 15.5454Z"
                            fill="white"
                          ></path>
                        </svg>
                      </div>
                      <div className="checkoutRightWrap">
                        <Title level={3} className="customHeading">
                          Choose Payments
                        </Title>

                        {/* Driver Tips and Special Comment Section */}


                        {/* Payment Method Selection */}
                        <Radio.Group
                          onChange={handlePaymentMethodChange}
                          value={paymentMethod}
                          className="paymentMethodGroup"
                        >
                          <Radio value="credit">Pay by Credit/Debit Card</Radio>
                          <Radio value="cash">Pay by Cash</Radio>
                        </Radio.Group>

                        {/* Credit/Debit Card Form */}
                        {paymentMethod === "credit" && (
                          <div className="cardPaymentWrap">
                            <p className="cardGroupWrap">
                              <span className="cardImgHeading">We Accept</span>
                              <div className="cardGroup">
                                <a href="#">
                                  <img src={visacard} alt="Visa Card" />
                                </a>
                                <a href="#">
                                  <img src={mastercard} alt="Master Card" />
                                </a>
                                <a href="#">
                                  <img src={americancard} alt="American Express" />
                                </a>
                                <a href="#">
                                  <img src={payoneercard} alt="Payoneer" />
                                </a>
                                <a href="#">
                                  <img src={applepaycard} alt="Apple Pay" />
                                </a>
                                <a href="#">
                                  <img src={banktransfercard} alt="Bank Transfer" />
                                </a>
                                <a href="#">
                                  <img src={discovercard} alt="Discover" />
                                </a>
                                <a href="#">
                                  <img src={jcbcard} alt="JCB" />
                                </a>
                              </div>
                            </p>
                            <Row gutter={15} style={{marginBottom:"15px"}}>
                            <Col xs={24} sm={24} md={24}>
                            <p className="cardImgHeading">Credit/Debit Card</p>
                            {/* Card Payment Form */}
                            <CardElement options={cardStyle} className="cardElement" />
                            </Col>
</Row>
                          </div>
                        )}

                        {/* Cash Payment Option */}
                        {paymentMethod === "cash" && (
                          <div className="cashPaymentWrap">
                            <p>You have selected to pay by Cash on Delivery.</p>
                          </div>
                        )}

                        <Form
                          name="layout-multiple-vertical"
                          layout="vertical"
                          className="checkoutForm"
                        >
                          <Row gutter={15}>
                            {/* Driver Tip Section */}
                            {orderType === 0 && ( <Col xs={24} sm={24} md={12}>
                            <Form.Item label="Rider Tips" name="driverTips" className="driverTipSection">
        <InputNumber
          min={0}
          max={100}
          step={1}
          placeholder="Enter Rider Tip (£)"
          value={driverTips} // Make sure to bind the value to state
          onChange={handleDriverTipChange} // Update the state on change
          size="large"
        />
      </Form.Item>
                            </Col>)}

                            {/* Special Instructions Section */}
                            <Col xs={24} sm={24} md={24}>
                              <Form.Item label="Special Comment" name="specialComment" className="specialCommentSection">
                                <TextArea
                                  id="specialComment"
                                  value={specialComment}
                                  onChange={handleSpecialCommentChange}
                                  placeholder="Enter special comment"
                                  rows={4} // Adjust the number of rows for the height of the text area
                                />
                              </Form.Item>
                            </Col>
                          </Row>

                        </Form>
                        {error && <p className="error">{error}</p>}
                        {/* Checkout Button */}
                        {orderType === 0 && subtotal > businessInfo?.businessInfo?.[0]?.charge_info?.[0]?.min_delivery_order && (<Button
                          type="primary"
                          className="checkoutButton"
                          onClick={handlePlaceOrder}
                          disabled={processing}
                        >
                         {paymentMethod === 'credit' ? 'Pay Now' : 'Place Order'} &pound;{grandTotal.toFixed(2)} <ArrowRightOutlined />
                        </Button> )}

                        {orderType === 1 && subtotal > businessInfo?.businessInfo?.[0]?.charge_info?.[0]?.min_pickup_order && (<Button
                          type="primary"
                          className="checkoutButton"
                          disabled={processing}
                          onClick={handlePlaceOrder}
                        >
                         {paymentMethod === 'credit' ? 'Pay Now' : 'Place Order'} &pound;{grandTotal.toFixed(2)} <ArrowRightOutlined />
                        </Button> )}
                      </div>
                    </div>
                  </div>
                )}

                {/* Payment Section End Here */}


              </Col>

              <Col xs={24} sm={9} md={9} xl={9} xxl={9}>
                <div className="checkoutCartSection commonSection">
                  <Title level={3} className="customHeading">
                    Cart Summary
                  </Title>
                  <div className="cartContainer">
                    <div className="cartWrap">
                      <div className="cartListItem">
                        <List
                          locale={{
                            emptyText: <CustomEmpty />,
                          }}
                          dataSource={cart.data || []}
                          renderItem={(item) => (
                            <List.Item key={item.id}>
                              <div className="headercartItemContainer">
                                {/* Main Item Details */}
                                <div className="headercartItemDetails">
                                  <div className="headercartItemName">
                                    {item.quantity} x {item.food_items.food_item_name_en}
                                  </div>
                                  <div className="headercartItemSize">
                                    {/*  <span>+ Nan Bread</span> <span>(1 X £8.95)</span> */}
                                  </div>
                                  <div className="headercartItemExtras">
                                    {/*  <span>+ Spicy Sauce</span> <span>(1 X £0.95)</span>, 
                                              <span>+ Lemon Juice</span> <span>(1 X £0.95)</span> */}
                                  </div>
                                </div>

                                {/* Quantity and Price Controls */}
                                <div className="headercartInputQtyWrap">
                                  <Row align="middle" justify="space-between">
                                    {/* Quantity Controls */}
                                    <div className="headercartQuantityControls">
                                      <Button type="default" className="headercartMinusBtn" icon={Cartloading ? (<Spin size="small" />) : ("")} onClick={() => handleUpdateItem(item.id, item.quantity - 1)}
                                        disabled={Cartloading} > - </Button>

                                      <div className="headercartInputQty">{item.quantity}</div>
                                      <Button type="default" className="headercartPlusBtn" icon={Cartloading ? (<Spin size="small" />) : ("")} onClick={() => handleUpdateItem(item.id, item.quantity + 1)}
                                        disabled={Cartloading} > + </Button>

                                    </div>
                                    {/* Price Details */}
                                    <div className="headercartItemPricedetails">&pound;{(item.quantity * item.delivery_item_price).toFixed(2)}</div>
                                  </Row>
                                </div>
                              </div>

                            </List.Item>
                          )}
                        />


                      </div>
                    </div>

                    {cart.data.length > 0 && (
                      <div className="headerCartOrderType applyCuponWrap">
                        <Title level={4} className="billHead">
                          Choose Order Type
                        </Title>

                        <Radio.Group
                          value={String(orderType)}
                          onChange={(e) => handleUpdateOrderType(e.target.value)}
                          buttonStyle="solid"
                          className="customRadioGroup"
                        >
                          {businessInfo?.businessInfo?.[0]?.portal_info?.[0]?.pickup_type_enable === 0 && (
                            <Radio.Button value="1" className="radioButton">
                              <div className="radioContent">
                                <svg viewBox="0 0 24 24" height="40" fill="currentColor">
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M13.869 3.284a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Zm2.109 9.162a8.519 8.519 0 0 1-2.418-1.266l-.288-.218a.25.25 0 0 0-.4.2v1.804a.993.993 0 0 0 .355.754 17.2 17.2 0 0 1 4.568 7.518 1.5 1.5 0 1 1-2.846.952 16.622 16.622 0 0 0-2.416-4.927.25.25 0 0 0-.433.081 17.332 17.332 0 0 1-2.56 5.309 1.5 1.5 0 1 1-2.344-1.873 12.745 12.745 0 0 0 2.213-4.7c.308-1.385.463-2.799.463-4.217v-1.357a.25.25 0 0 0-.38-.214 3.413 3.413 0 0 0-1.622 2.944 1.5 1.5 0 0 1-1.5 1.426 1.5 1.5 0 0 1-1.5-1.613 6.32 6.32 0 0 1 3.511-5.571 5.7 5.7 0 0 1 5.963.54l1.016.762a5.514 5.514 0 0 0 1.564.82l1.105.367a1.551 1.551 0 0 1 1.083 1.673 1.5 1.5 0 0 1-1.958 1.198l-1.176-.392Z"
                                  ></path>
                                </svg>
                                <span>{businessInfo?.businessInfo?.[0]?.homelng?.[0]?.collection_en ?? "Collection"}</span>
                                <span>{businessInfo?.businessInfo?.[0]?.portal_info?.[0]
    ?.pickup_estimate_time}min Approx</span>
                              </div>
                            </Radio.Button>
                          )}
                          {businessInfo?.businessInfo?.[0]?.portal_info?.[0]?.delivery_type_enable === 0 && (
                            <Radio.Button value="0" className="radioButton">
                              <div className="radioContent">
                                <svg viewBox="0 0 24 24" height="40" fill="currentColor">
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M4.401 9.602c.281.281.666.439 1.062.439h.039a1.48 1.48 0 0 0 1.075-.496l3.157-3.507a1.505 1.505 0 0 0-.055-2.071L8.098 2.385A1.547 1.547 0 0 0 7 1.947c-.41.01-.802.191-1.076.495l-3.16 3.512a1.502 1.502 0 0 0 .054 2.066l1.583 1.582Zm6.321 2.132 1.451-1.659a8.276 8.276 0 0 0 4.655 1.42h.672a1.5 1.5 0 1 0 0-3h-.672a5.363 5.363 0 0 1-3.767-1.561 1.407 1.407 0 0 0-1.111-.438 1.5 1.5 0 0 0-1.079.511l-3.5 4a1.5 1.5 0 0 0 .3 2.236l.664.442a5.261 5.261 0 0 1 2.2 5.484 1.5 1.5 0 1 0 2.928.651 8.285 8.285 0 0 0-2.741-8.086ZM22 18.495a2.5 2.5 0 1 0-5 0 2.5 2.5 0 0 0 5 0Zm2 0a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm-17 0a2.5 2.5 0 1 0-5 0 2.5 2.5 0 0 0 5 0Zm2 0a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm3.125-14.5a2.375 2.375 0 1 1 4.75 0 2.375 2.375 0 0 1-4.75 0Z"
                                  ></path>
                                </svg>
                                <span>{businessInfo?.businessInfo?.[0]?.homelng?.[0]?.delivery_en ?? "Delivery"}</span>
                                <span>{businessInfo?.businessInfo?.[0]?.portal_info?.[0]
    ?.delivery_estimate_time}min Approx</span>
                              </div>
                            </Radio.Button>
                          )}
                        </Radio.Group>
                      </div>
                    )}
                    {coupon_discount <= 0 && (
                    <div className="applyCuponWrap">
                      <Space.Compact style={{ width: "100%" }}>
                        <Input placeholder="Enter promo code"  value={CouponCode}
        onChange={handleCouponCodeChange} />
                        <Button type="primary" icon={Cartloading ? (<Spin size="small" />) : ("")} disabled={Cartloading} onClick={handleCouponCode}>
                          <svg
                            width="40px"
                            height="40px"
                            viewBox="0 0 38 39"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g>
                              <path
                                d="M17.5388 3.56344C18.3293 2.71762 19.6706 2.71762 20.4611 3.56344L22.9716 6.24958C23.3659 6.67144 23.9233 6.9023 24.5003 6.8828L28.1749 6.75862C29.332 6.71951 30.2805 7.66797 30.2414 8.82503L30.1172 12.4996C30.0977 13.0767 30.3285 13.6341 30.7504 14.0284L33.4365 16.5389C34.2823 17.3294 34.2824 18.6707 33.4365 19.4612L30.7504 21.9717C30.3285 22.366 30.0977 22.9234 30.1172 23.5005L30.2414 27.1751C30.2805 28.3321 29.332 29.2806 28.1749 29.2415L24.5003 29.1173C23.9233 29.0978 23.3659 29.3287 22.9716 29.7505L20.4611 32.4367C19.6706 33.2825 18.3293 33.2825 17.5388 32.4367L15.0282 29.7505C14.634 29.3287 14.0766 29.0978 13.4995 29.1173L9.82491 29.2415C8.66785 29.2806 7.71939 28.3321 7.75849 27.1751L7.88268 23.5005C7.90218 22.9234 7.67132 22.366 7.24946 21.9717L4.56332 19.4612C3.7175 18.6707 3.7175 17.3294 4.56331 16.5389L7.24946 14.0284C7.67132 13.6341 7.90218 13.0767 7.88268 12.4996L7.75849 8.82503C7.71939 7.66797 8.66785 6.71951 9.8249 6.75862L13.4995 6.8828C14.0766 6.9023 14.634 6.67144 15.0282 6.24958L17.5388 3.56344Z"
                                fill="#fff"
                              ></path>
                            </g>
                            <path
                              d="M16.8722 16.7567C15.2934 16.7567 14 15.4608 14 13.8784C14 12.2961 15.2783 11 16.8722 11C18.4662 11 19.7444 12.2959 19.7444 13.8784C19.7444 15.4606 18.4511 16.7567 16.8722 16.7567ZM16.8722 13.1249C16.4512 13.1249 16.1052 13.4716 16.1052 13.8935C16.1052 14.3154 16.4512 14.6621 16.8722 14.6621C17.2932 14.6621 17.6392 14.3154 17.6392 13.8935C17.6392 13.4716 17.2932 13.1249 16.8722 13.1249ZM21.1278 25C19.5489 25 18.2556 23.7041 18.2556 22.1216C18.2556 20.5394 19.5487 19.2433 21.1278 19.2433C22.7066 19.2433 24 20.5392 24 22.1216C24 23.7039 22.7069 25 21.1278 25ZM21.1278 21.353C20.7067 21.353 20.3608 21.6996 20.3608 22.1216C20.3608 22.5435 20.7068 22.8902 21.1278 22.8902C21.5488 22.8902 21.8948 22.5435 21.8948 22.1216C21.8948 21.6996 21.5488 21.353 21.1278 21.353ZM15.5638 22.3928C15.2932 22.3928 15.0226 22.2872 14.8119 22.0764C14.406 21.6696 14.406 20.9913 14.8119 20.5845L21.624 13.803C22.03 13.3962 22.7068 13.3962 23.1127 13.803C23.5187 14.2098 23.5187 14.8881 23.1127 15.2949L16.3006 22.0764C16.1051 22.2872 15.8345 22.3928 15.5638 22.3928Z"
                              fill="#009de0"
                            ></path>
                            <defs>
                              <filter
                                id="filter0_d_5069_99384"
                                x="0.0718119"
                                y="0.357648"
                                width="37.8561"
                                height="37.8563"
                                filterUnits="userSpaceOnUse"
                                color-interpolation-filters="sRGB"
                              >
                                <feFlood
                                  flood-opacity="0"
                                  result="BackgroundImageFix"
                                ></feFlood>
                                <feColorMatrix
                                  in="SourceAlpha"
                                  type="matrix"
                                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                  result="hardAlpha"
                                ></feColorMatrix>
                                <feOffset dy="1.28571"></feOffset>
                                <feGaussianBlur stdDeviation="1.92857"></feGaussianBlur>
                                <feComposite
                                  in2="hardAlpha"
                                  operator="out"
                                ></feComposite>
                                <feColorMatrix
                                  type="matrix"
                                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"
                                ></feColorMatrix>
                                <feBlend
                                  mode="normal"
                                  in2="BackgroundImageFix"
                                  result="effect1_dropShadow_5069_99384"
                                ></feBlend>
                                <feBlend
                                  mode="normal"
                                  in="SourceGraphic"
                                  in2="effect1_dropShadow_5069_99384"
                                  result="shape"
                                ></feBlend>
                              </filter>
                            </defs>
                          </svg>
                          Apply
                        </Button>
                      </Space.Compact>
                    </div>)}
                    {/* Dynamic Calculation Logic */}
                    {cart.data?.length > 0 && (() => {

                      return (
                        <div className="applyCuponWrap">

                          {/* Delivery = 0 and Collection = 1 */}
                          {orderType === 0 &&
                            subtotal < businessInfo?.businessInfo?.[0]?.charge_info?.[0]?.min_delivery_order && (
                              <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: '#ffe6e6',
                                padding: '10px',
                                marginBottom: '10px',
                                borderRadius: '4px'
                              }}>

                                <Title level={4} style={{ margin: 0 }}>
                                  The minimum order value is &pound;{businessInfo?.businessInfo?.[0]?.charge_info?.[0]?.min_delivery_order}
                                </Title>
                              </div>
                            )}

                          {orderType === 1 &&
                            subtotal < businessInfo?.businessInfo?.[0]?.charge_info?.[0]?.min_pickup_order && (
                              <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: '#ffe6e6',
                                padding: '10px',
                                marginBottom: '10px',
                                borderRadius: '4px'
                              }}>

                                <Title level={4} style={{ margin: 0 }}>
                                  The minimum order value is &pound;{businessInfo?.businessInfo?.[0]?.charge_info?.[0]?.min_pickup_order}
                                </Title>
                              </div>
                            )}


                          <Title level={4} className="billHead">Bill Summary</Title>
                          <div className="billSummary">
                            <div className="billItemWrap">
                              <div className="billItemTitle">Sub Total</div>
                              <div className="billItemValue">&pound;{subtotal.toFixed(2)}</div>
                            </div>
                            {orderType === 0 && deliveryCharge > 0 && (
                              <div className="billItemWrap">
                                <div className="billItemTitle">Delivery Fee</div>
                                <div className="billItemValue">&pound;{deliveryCharge.toFixed(2)}</div>
                              </div>)}
                            {orderType === 0 && businessInfo?.businessInfo?.[0]?.charge_info?.[0]?.packaging_charge_enable === 1 && (
                              <div className="billItemWrap">
                                <div className="billItemTitle">Packaging Fee</div>
                                <div className="billItemValue">&pound;{PackageFee.toFixed(2)}</div>
                              </div>
                            )}
                             {orderType === 0 && driverTips > 0 && (<div className="billItemWrap">
                              <div className="billItemTitle">Rider Tip Amount</div>
                              <div className="billItemValue">&pound;{driverTips.toFixed(2)}</div>
                            </div>)}
                            {coupon_discount > 0 && (<div className="billItemWrap">
                              <div className="billItemTitle">Coupon Discount Amount</div>
                              <div className="billItemValue">&pound;{coupon_discount.toFixed(2)}</div>
                            </div>)}
                            {discount > 0 && (<div className="billItemWrap">
                              <div className="billItemTitle">Direct Discount Amount</div>
                              <div className="billItemValue">&pound;{discount.toFixed(2)}</div>
                            </div>)}
                            <div className="billItemWrap">
                              <div className="billItemTitle">Grand Total</div>
                              <div className="billItemValue">&pound;{grandTotal.toFixed(2)}</div>
                            </div>
                            {totalSavings > 0 && (<div className="billTotalDiscountWrap">
                              <div className="billTotalDiscountTitle">Your Total Direct Order Savings</div>
                              <div className="billTotalDiscountValue">&pound;{totalSavings.toFixed(2)}</div>
                            </div>)}
                          </div>




                        </div>
                      );
                    })()}

                  </div>
                </div>
              </Col>
            </Row>

          ) : (
            <CustomEmpty />
          )}
        </div>
      </div>
    </div>
  );
};

export default Checkout;
