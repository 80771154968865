import React, { useState, useEffect, useContext } from "react";
import { BusinessContext } from "../../context/BusinessContext";
import { Layout, Row, Col, Menu, Typography } from "antd";
import { Link } from "react-router-dom";
import {
  HomeOutlined,
  ShoppingCartOutlined,
  UserOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import gplay from "../../assets/images/play_store.png";
import appstore from "../../assets/images/app_store.png";
import "../layouts/Footer.css";

const { Title } = Typography;
const currentYear = new Date().getFullYear();
const { Footer } = Layout;

const MyFooter = () => {
  const { businessInfo } = useContext(BusinessContext);

  const [isAtTop, setIsAtTop] = useState(true);

  // Handle scroll events to toggle button visibility
  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const pageHeight = document.documentElement.scrollHeight;
    const windowHeight = window.innerHeight;

    // If scrolled to the bottom
    if (scrollPosition + windowHeight >= pageHeight - 100) {
      setIsAtTop(false); // We're at the bottom
    } else if (scrollPosition <= 100) {
      setIsAtTop(true); // We're at the top
    }
  };

  // Handle the scroll-to-top or scroll-to-bottom behavior on arrow click
  const handleArrowClick = () => {
    if (isAtTop) {
      window.scrollTo({ top: document.documentElement.scrollHeight, behavior: "smooth" }); // Scroll to bottom
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to top
    }
  };

  // Add scroll event listener when component mounts
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  // Check if businessInfo and its properties are loaded
  const restaurantName =
    businessInfo?.businessInfo?.[0]?.restaurant_name_en ||
    "Default Restaurant Name";

  const restaurantLogo =
    businessInfo?.businessInfo?.[0]?.restaurant_logo ||
    "https://a.storyblok.com/f/177063/126x125/4567c283c7/tfk-logo.svg";

  // Ensure businessInfo is available before rendering footer content
  if (!businessInfo || !businessInfo.businessInfo) {
    return ""; // Placeholder content while data is loading
  }


  return (
    <>
      {/* Desktop Footer */}
      <Footer className="footerSection desktopFooter">
        <div className="topFooter">
          <Row gutter={20}>
            <Col xs={24} sm={24} md={16} xl={16} xxl={16}>
              <div className="footerLeft">
                

                {businessInfo?.businessInfo?.[0]?.food_hygine_rating_img && (
                  <Link
                    to={businessInfo?.businessInfo?.[0]?.food_hygine_rating_url}
                    className=""
                    target="_blank"
                  >
                    <img
                      style={{ width: "42%", marginLeft: "10px" }}
                      src={
                        businessInfo?.businessInfo?.[0]?.food_hygine_rating_img
                      }
                      alt={restaurantName}
                      title={restaurantName}
                    />
                  </Link>
                )}

                <Menu mode="horizontal" className="footerMenu">
                  {businessInfo?.businessInfo?.[0]?.homelng?.[0]
                    ?.about_us_en && (
                    <Menu.Item key="1">
                      <a
                        href="/about-us"
                        title={
                          businessInfo?.businessInfo?.[0]?.homelng?.[0]
                            ?.about_us_en
                        }
                      >
                        {
                          businessInfo?.businessInfo?.[0]?.homelng?.[0]
                            ?.about_us_en
                        }
                      </a>
                    </Menu.Item>
                  )}

                  {businessInfo?.businessInfo?.[0]?.homelng?.[0]
                    ?.gallery_en && (
                    <Menu.Item key="2">
                      <a
                        href="/gallery"
                        title={
                          businessInfo?.businessInfo?.[0]?.homelng?.[0]
                            ?.gallery_en
                        }
                      >
                        {
                          businessInfo?.businessInfo?.[0]?.homelng?.[0]
                            ?.gallery_en
                        }
                      </a>
                    </Menu.Item>
                  )}

                  {businessInfo?.businessInfo?.[0]?.homelng?.[0]
                    ?.privacy_policy_en && (
                    <Menu.Item key="3">
                      <a
                        href="/privacy"
                        title={
                          businessInfo?.businessInfo?.[0]?.homelng?.[0]
                            ?.privacy_policy_en
                        }
                      >
                        {
                          businessInfo?.businessInfo?.[0]?.homelng?.[0]
                            ?.privacy_policy_en
                        }
                      </a>
                    </Menu.Item>
                  )}

                  {businessInfo?.businessInfo?.[0]?.homelng?.[0]
                    ?.terms_conditions_en && (
                    <Menu.Item key="4">
                      <a
                        href="/terms-conditions"
                        title={
                          businessInfo?.businessInfo?.[0]?.homelng?.[0]
                            ?.terms_conditions_en
                        }
                      >
                        {
                          businessInfo?.businessInfo?.[0]?.homelng?.[0]
                            ?.terms_conditions_en
                        }
                      </a>
                    </Menu.Item>
                  )}

                  {businessInfo?.businessInfo?.[0]?.homelng?.[0]
                    ?.allergy_en && (
                    <Menu.Item key="5">
                      <a
                        href="/nutrition-allergy"
                        title={
                          businessInfo?.businessInfo?.[0]?.homelng?.[0]
                            ?.allergy_en
                        }
                      >
                        {
                          businessInfo?.businessInfo?.[0]?.homelng?.[0]
                            ?.allergy_en
                        }
                      </a>
                    </Menu.Item>
                  )}

                  {businessInfo?.businessInfo?.[0]?.homelng?.[0]
                    ?.contact_us_en && (
                      <Menu.Item
                      key="contact-us"
                     
                    >
                      <Link to="/contact-us"  title={
                  businessInfo?.businessInfo?.[0]?.homelng?.[0]
                    ?.contact_us_en
                }>{
                  businessInfo?.businessInfo?.[0]?.homelng?.[0]
                    ?.contact_us_en
                }</Link>
                    </Menu.Item>
                  )}

                   
                </Menu>
              </div>
            </Col>
            <Col xs={24} sm={24} md={8} xl={8} xxl={8}>
              <Title level={4} className="footerHeading">
                {businessInfo?.businessInfo?.[0]?.homelng?.[0]
                  ?.download_app_en || "Download the Curry Inn app"}
              </Title>
              <Title level={5} className="downloadapp_subtitle">
                {businessInfo?.businessInfo?.[0]?.homelng?.[0]
                  ?.download_anywhere_en || " Order anytime and anywhere!"}
              </Title>
              <div className="footerAppStore">
                <Link className="appStoreBtn">
                  <img src={gplay} alt="Google Play" />
                </Link>
                <Link className="appStoreBtn">
                  <img src={appstore} alt="App Store" />
                </Link>
                <p>Our Trusted Delivery Partner - PDQ2.com Driver Network!</p>
              </div>
            </Col>
          </Row>
        </div>
        <div className="copyrightFooter">
          <Link to="https://pdq2.com/" target="_blank" className="ftLogo">
            <img
              style={{ width: "85px" }}
              src="https://admin.pdq2.com/site_logo/66cf64663a84bc6.jpg"
              alt="Restaurant Logo"
            />
          </Link>
          <Title
                          level={1}
                          
                          style={{ color: "#fff", fontSize:"17px" }}
                        >
                          {businessInfo?.businessInfo?.[0]?.homelng?.[0]
                            ?.pdq2_delivery_description_en ||
                            "For your safety and security. Our deliveries are now handle by PDQ2.COM"}
                        </Title>
          <div style={{ fontSize: "15px", fontWeight: 500 }}>
            {businessInfo?.businessInfo?.[0]?.homelng?.[0]?.powered_by_en ||
              "Powered By "}{" "}
            PDQ2.com
          </div>
        </div>

        <div className="copyrightFooter" style={{ paddingBottom: 0 }}>
          {restaurantName} {currentYear}{" "}
          {businessInfo?.businessInfo?.[0]?.homelng?.[0]?.created_by_en ||
            "Created By "}{" "}
          PDQ2 Group Limited
        </div>
      </Footer>

     {/* Scroll to Top/Bottom Button */}
     <div
        className="scroll-to-top"
        onClick={handleArrowClick}
      >
        {isAtTop ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M5 15l7-7 7 7"></path>
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M19 9l-7 7-7-7"></path>
          </svg>
        )}
      </div>


      {/* Mobile Footer */}
      <Footer className="mobileFooter">
        <Menu mode="horizontal" className="mobileMenu">
          <Menu.Item key="home" icon={<HomeOutlined />}>
            <Link to="/">Home</Link>
          </Menu.Item>
          <Menu.Item key="cart" icon={<ShoppingCartOutlined />}>
            <Link to="/cart">Cart</Link>
          </Menu.Item>
          <Menu.Item key="account" icon={<UserOutlined />}>
            <Link to="/account">My Account</Link>
          </Menu.Item>
          <Menu.Item key="more" icon={<MoreOutlined />}>
            <Link to="/more">More</Link>
          </Menu.Item>
        </Menu>
      </Footer>
    </>
  );
};

export default MyFooter;
